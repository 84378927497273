import styles from './AddDateModal.m.less'
import WrappedDateItem from "../../../../../DateItem"
import { DeleteIcon } from "../../../../../Icons"
import { Button } from "../../../../../button"
import { Moment, dfaValidators } from '../../../../../utils'
import { FC, useContext } from 'react'
import { StepperContext } from '../../../../../Stepper'
import FormItem from 'antd/lib/form/FormItem'
import classNames from 'classnames'
import { DecisionMakingType } from '@dltru/dfa-models'
import { Form, FormInstance } from '../../../../../components/Form'

type ComponentProps = {
  form: FormInstance
}

export const NoRepeatFrequency: FC<ComponentProps> = ({form}) => {
  const { values } = useContext(StepperContext)
  const startDate = values?.decision_making_type === DecisionMakingType.platform ?
    values?.issue_end_date : values?.issue_start_date

  return <>
    <Form.List name="dates">
      {(fields, { add, remove }) => (
        <>
            {fields.map((field) => (
              <div className={styles.date_picker_wrapper} key={field.key}>
                <FormItem 
                  valuePropName='date' 
                  {...field} 
                  name={field.name.toString()}
                  rules={[
                    {
                      validator: dfaValidators.couponPaymentDate(
                        startDate as Moment,
                        values?.redeem_date as Moment,
                      ),
                    },
                  ]}
                  validateTrigger={["onChange", "onBlur"]}
                  required
                >
                  <WrappedDateItem 
                    className={classNames(styles.date_picker, styles.inner)}
                  />
                </FormItem>
                {fields.length > 1 && 
                  <Button 
                    className={styles.delete_date_btn}
                    type='link' 
                    icon={<DeleteIcon />} 
                    onClick={() => {
                      form.validateFields([field.name])
                      remove(field.name)
                    }} 
                  />
                }
              </div>
            ))}
          <Button
            type="link"
            onClick={() => add()}
          >
            Добавить ещё
          </Button>
        </>
      )}
    </Form.List>
  </>
}