import { useContext } from 'react'
import WrappedDateItem from '../../../../../DateItem'
import styles from './AddDateModal.m.less'
import { Moment, dfaValidators } from '../../../../../utils'
import { StepperContext } from '../../../../../Stepper'
import { DecisionMakingType } from '@dltru/dfa-models'

export const OnceInNMonthsFrequency = () => {
  const { values } = useContext(StepperContext)
  const startDate = values?.decision_making_type === DecisionMakingType.platform ? 
    values?.issue_end_date : values?.issue_start_date

  return <>
    <WrappedDateItem
      className={styles.date_picker}
      name='payment_date_start'
      label="Начало периода выплат"
      required
      rules={[
        (formInstance) => ({
          validator: dfaValidators.couponPaymentsStartDate(
            startDate as Moment,
            values?.redeem_date as Moment,
            !formInstance.getFieldError('payment_date_end')[0] ?
            formInstance.getFieldValue('payment_date_end') as Moment : undefined
          ),
        }),
      ]}
    />
    <WrappedDateItem
      className={styles.date_picker}
      name='payment_date_end'
      label="Окончание периода выплат"
      required
      rules={[
        (formInstance) => ({
          validator: dfaValidators.couponPaymentsEndDate(
            startDate as Moment,
            values?.redeem_date as Moment,
            !formInstance.getFieldError('payment_date_start')[0] ?
            formInstance.getFieldValue('payment_date_start') as Moment : undefined,
          ),
        }),
      ]}
    />
  </>
}