// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card_tQhBe {\n  border-radius: 8px;\n}\n.card_tQhBe.view_Ig4DT {\n  border: none;\n}\n.card_inner_w0WC8 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.card_inner_w0WC8:hover .controls_f12Y7 {\n  transition: opacity 0.1s ease-in-out;\n  opacity: 1;\n}\n.controls_f12Y7 {\n  opacity: 0;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.controls_f12Y7 span > svg {\n  color: #303440;\n  width: 16px;\n  height: 16px;\n}\n.controls_f12Y7 span > svg:hover {\n  color: #007ef2;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/dfaForm/CouponPayments/PaymentsScheduler/PaymenDateItem/PaymentDateItem.m.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAKA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAHF;AAKE;EAEI,oCAAA;EACA,UAAA;AAJN;AASA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAPF;AAGA;EAOI,cAAA;EACA,WAAA;EACA,YAAA;AAPJ;AASI;EACE,cAAA;AAPN","sourcesContent":["@import 'style/palette';\n\n.card {\n  border-radius: 8px;\n\n  &.view {\n    border: none;\n  }\n}\n\n.card_inner {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &:hover {\n    .controls {\n      transition: opacity 0.1s ease-in-out;\n      opacity: 1;\n    }\n  }\n}\n\n.controls {\n  opacity: 0;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  span > svg {\n    color: @gray-10;\n    width: 16px;\n    height: 16px;\n\n    &:hover {\n      color: @primary-6;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card_tQhBe",
	"view": "view_Ig4DT",
	"card_inner": "card_inner_w0WC8",
	"controls": "controls_f12Y7"
};
export default ___CSS_LOADER_EXPORT___;
