// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".date_picker_wrapper_Y_v4q {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  padding-bottom: 20px;\n}\n.date_picker_CzsrR {\n  width: 300px;\n}\n.date_picker_CzsrR.inner_vQdBe [class='ant-form-item-control-input'] {\n  padding-bottom: 10px !important;\n}\n.delete_date_btn_tcdv0 {\n  padding-bottom: 24px !important;\n}\n.delete_date_btn_tcdv0 span > svg {\n  color: #303440;\n  width: 16px;\n  height: 16px;\n}\n.delete_date_btn_tcdv0 span > svg:hover {\n  color: #007ef2;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/dfaForm/CouponPayments/PaymentsScheduler/AddDateModal/AddDateModal.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,oBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAIE;EAEI,+BAAA;AAHN;AAQA;EACE,+BAAA;AANF;AAKA;EAII,cAAA;EACA,WAAA;EACA,YAAA;AANJ;AAQI;EACE,cAAA;AANN","sourcesContent":["@import 'style/palette';\n\n.date_picker_wrapper {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  padding-bottom: 20px;\n}\n\n.date_picker {\n  width: 300px;\n\n  &.inner {\n    [class='ant-form-item-control-input'] {\n      padding-bottom: 10px !important;\n    }\n  }\n}\n\n.delete_date_btn {\n  padding-bottom: 24px !important;\n\n  span>svg {\n    color: @gray-10;\n    width: 16px;\n    height: 16px;\n\n    &:hover {\n      color: @primary-6;\n    }\n  }\n}\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date_picker_wrapper": "date_picker_wrapper_Y_v4q",
	"date_picker": "date_picker_CzsrR",
	"inner": "inner_vQdBe",
	"delete_date_btn": "delete_date_btn_tcdv0"
};
export default ___CSS_LOADER_EXPORT___;
