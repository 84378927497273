import { FC } from 'react'
import styles from './PaymentDateItem.m.less'
import { Card } from '../../../../../card'
import { Checkbox, CheckboxProps } from '../../../../../Checkbox'
import { Button } from '../../../../../button'
import { DeleteIcon, EditPenIcon } from '../../../../../Icons'
import classNames from 'classnames'

type TPaymentDateItemProps = {
  isEdit: boolean,
  onEdit?: () => void,
  onDelete?: () => void,
} & CheckboxProps

export const PaymentDateItem: FC<TPaymentDateItemProps> = ({
  children, 
  isEdit, 
  onEdit, 
  onDelete,
  ...rest
}) => {

  if (isEdit) {
    return <Card padding={10} margin={0} width={200} className={styles.card}>
    <div className={styles.card_inner}>
      <Checkbox {...rest}>{children}</Checkbox>
      <div className={styles.controls}>
          <Button type='link' icon={<EditPenIcon />} onClick={onEdit} />
          <Button type='link' icon={<DeleteIcon />} onClick={onDelete} />
      </div>
    </div>
  </Card>
  }

  return <Card padding={10} margin={0} width={200} className={classNames(styles.card, styles.view)}>{children}</Card>
}