import { FC } from "react"
import { Modal, ModalProps } from "../../../../../components/Modal"
import { Space } from "../../../../../Space"
import { QuestionCircle } from "../../../../../Icons"
import { Button } from "../../../../../button"
import { Box } from "../../../../../Box"

interface IDeleteDateModalProps extends ModalProps {
  handleDelete: () => void
}

export const DeletetDateModal: FC<IDeleteDateModalProps> = (props) => {
  return <Modal
    {...props} 
    title={
      <Space size={16}>
        <QuestionCircle className="auth-modal__gray-icon" />
        Удалить даты выплат
      </Space>
    }
    footer={[
      <Button borderRadius={12} onClick={props.onCancel}>
        Отмена
      </Button>,
      <Button type="primary" borderRadius={12} onClick={props.handleDelete}>
        Да, удалить
      </Button>,
    ]}
    >
    <Box margin={[0, 60]}>
      Вы действительно хотите удалить выбранные даты выплат купонного дохода?
    </Box>
  </Modal>
}