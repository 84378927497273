import { FC, useCallback, useContext, useEffect, useState } from "react"
import { Form } from "../../../../../components/Form"
import { Modal, ModalProps } from "../../../../../components/Modal"
import { Button } from "../../../../../button"
import WrappedDateItem from "../../../../../DateItem"
import moment, { Moment } from "moment"
import { VIEW_DATE_FORMAT } from "../../../../../constants"
import { dfaValidators } from "../../../../../utils"
import { StepperContext } from "../../../../../Stepper"
import { FieldData } from 'rc-field-form/lib/interface'

type IEditDateModalProps = {
  date: string
  handleEdit: (date: Moment) => void
} & ModalProps

export const EditDateModal: FC<IEditDateModalProps> = (props) => {
  const [form] = Form.useForm<{ newPaymentDate: string }>()
  const { values } = useContext(StepperContext)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    return () => form.resetFields()
  }, [])

  const setErrorIfInvalidFields = (_: FieldData[], fields: FieldData[]) => {
    setIsError(fields.some(field => field.errors && field.errors.length > 0))
  }

  const onCancel = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.onCancel) {
      props.onCancel(e)
      form.resetFields()
    }
  }, [])

  return <Modal
    {...props} 
    title="Изменение даты выплаты"
    onCancel={onCancel}
    footer={[
      <Button borderRadius={12} onClick={onCancel}>
        Отмена
      </Button>,
      <Button 
        borderRadius={12} 
        type="primary" 
        onClick={() => props.handleEdit(form.getFieldValue('newPaymentDate'))}
        disabled={isError}
        >
        Сохранить
      </Button>,
    ]}
  >
    <Form form={form} layout="vertical" onFieldsChange={setErrorIfInvalidFields}>
      <WrappedDateItem
        label="Новая дата выплаты"
        name="newPaymentDate"
        required
        initialValue={moment(props.date, VIEW_DATE_FORMAT)}
        rules={[
          {
            validator: dfaValidators.couponPaymentDate(
              values?.issue_start_date as Moment,
              values?.redeem_date as Moment,
            ),
          },
        ]}
      />
    </Form>
  </Modal>
}