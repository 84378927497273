import { FC, useCallback, useState } from 'react'
import { TAddDates, TPeriodType, couponPaymentFrequency } from '@dltru/dfa-models'
import { NoRepeatFrequency } from './NoRepeatFrequency'
import { OnceInNMonthsFrequency } from './OnceInNMonthsFrequency'
import { Modal, ModalProps } from '../../../../../components/Modal'
import { Form, WrappedSelect } from '../../../../../components/Form'
import { Button } from '../../../../../button'
import { Box } from '../../../../../Box'
import { Divider } from '../../../../../Divider'
import { Moment } from 'moment'
import { VIEW_DATE_FORMAT } from '../../../../../constants'
import { FieldData } from 'rc-field-form/lib/interface'

const optionsCouponPaymentFrequency = Object.entries(couponPaymentFrequency).map(
    ([value, label]) => ({
        key: value,
        value,
        label,
    }),
)

type TAddModalProps = {
    onFinish: (data: TAddDates) => void
} & ModalProps

interface INoRepeatFrequencyFormValues {
    period_type: TPeriodType.no_repeat
    dates: Moment[]
}

interface IOnceInNMonthsFrequencyFormValues {
    period_type: Exclude<TPeriodType, TPeriodType.no_repeat>
    payment_date_start: Moment
    payment_date_end: Moment
}

export type AddDatesFormValues = INoRepeatFrequencyFormValues | IOnceInNMonthsFrequencyFormValues

export const AddDateModal: FC<TAddModalProps> = (props) => {
    const [form] = Form.useForm<AddDatesFormValues>()
    const [frequencyType, setFrequencyType] = useState<TPeriodType>(TPeriodType.no_repeat)
    const [isError, setIsError] = useState(false)

    const onChangeFrequencyHandler = useCallback((v: TPeriodType) => {
        setFrequencyType(v)
        form.resetFields(['payment_date_start', 'payment_date_end'])
    }, [])

    const onCancel = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (props.onCancel) {
            props.onCancel(e)
            form.resetFields()
            setFrequencyType(TPeriodType.no_repeat)
        }
    }, [])

    const setErrorIfInvalidFields = (_: FieldData[], fields: FieldData[]) => {
        const isFieldsError = fields?.some((field) => field.errors && field.errors.length > 0)
        const data = form.getFieldsValue()
        if (data.period_type === TPeriodType.no_repeat) {
            const isDatesFieldEmtpy = data.dates?.some((date) => !date)
            setIsError(isFieldsError || isDatesFieldEmtpy)
        } else {
            setIsError(isFieldsError || !data.payment_date_start || !data.payment_date_end)
        }
    }

    const onSubmit = useCallback(() => {
        const data = form.getFieldsValue()
        if (data.period_type === TPeriodType.no_repeat) {
            props.onFinish({
                ...data,
                dates: data.dates.map((date) => date.format(VIEW_DATE_FORMAT)),
            })
        } else {
            props.onFinish({
                ...data,
                payment_date_start: data.payment_date_start.format(VIEW_DATE_FORMAT),
                payment_date_end: data.payment_date_end.format(VIEW_DATE_FORMAT),
            })
        }
        form.resetFields()
        setFrequencyType(TPeriodType.no_repeat)
    }, [])

    return (
        <Modal
            {...props}
            onCancel={onCancel}
            title="Добавление даты выплаты"
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button
                    type="primary"
                    borderRadius={12}
                    onClick={onSubmit}
                    disabled={isError}
                    htmlType="submit"
                >
                    Добавить в график выплат
                </Button>,
            ]}
        >
            <Box>
                Определите конкретные даты выплат или выберите правило, указав периодичность и
                период выплат. Сформированные даты будут добавлены в график выплат.
            </Box>
            <Divider />
            <Form
                form={form}
                layout="vertical"
                initialValues={{ dates: [null], period_type: TPeriodType.no_repeat }}
                onFieldsChange={setErrorIfInvalidFields}
            >
                <WrappedSelect
                    name="period_type"
                    label="Периодичность выплат"
                    options={optionsCouponPaymentFrequency}
                    onChange={onChangeFrequencyHandler}
                />
                {frequencyType === TPeriodType.no_repeat ? (
                    <NoRepeatFrequency form={form} />
                ) : (
                    <OnceInNMonthsFrequency />
                )}
            </Form>
        </Modal>
    )
}
